import { createApp } from 'vue'
import App from './App.vue'

const app = createApp(App);

import '@/utils/base.css';

import 'vant/lib/index.css';
import { Pagination, Icon } from 'vant';
app.use(Pagination);
app.use(Icon);

import router from "./router/index.js" //引入配置路由文件
app.use(router); //记得在mount之前调用


// 复制 npm install --save vue-clipboard2
import VueClipboard from 'vue-clipboard2';
VueClipboard.config.autoSetContainer = true;
app.use(VueClipboard);

import i18n from './i18n.js'; //多语言
app.use(i18n);

app.mount('#app');

//保证页面跳转时页面在顶部
router.afterEach((to, from, next) => {
  window.scrollTo(0, 0)
})