/* 千位符分割
	val：要分割千位的数字
	dec：要保留的小数位数
*/
export function kilobit(val, dec) {
  let str = val + '';
  str = str.replace(/^(\d*)$/, "$1.");
  str = (str + "00000000").replace(/(\d*\.\d\d\d\d\d\d\d\d)\d*/, "$1");
  str = str.replace(".", ",");
  var re = /(\d)(\d{3},)/;
  while (re.test(str))
    str = str.replace(re, "$1,$2");
  str = str.replace(/,(\d\d\d\d\d\d\d\d)$/, ".$1");
  str.replace(/^\./, "0.");
  let index = str.indexOf('.');
  return str.substring(0, index+dec*1+1);
}


export function times(val) {
	var date = new Date(val * 1000);
	console.log(date)
	// var date = new Date(date * 1000);
	// console.log(date)
	var Y = date.getFullYear() + '-';
	var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
	var D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' ';
	var h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
	var m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':';
	var s = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
	let str = `<text style="font-size: 20px;">` + Y + M + D + `</text></br><text style="font-size: 15px;color:#7A7B89;">` + h + m + +s + `</text>`;
	return str;
	// return Y + M + D + h + m + s;
}

export function strCutting(val,n) {
  val = '' + val;
  var leng = val.length;
  val = val.substring(0, n) + '...' + val.substring(leng - n, leng)
  return val
}

export function isMobile() {
  let flag = navigator.userAgent.match(
    /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
  );
  return flag;
}

