<template>
	<router-view />
</template>

<script setup>
	import {reactive,ref,getCurrentInstance,onMounted,inject} from 'vue';
	const {proxy} = getCurrentInstance();
	import { isMobile } from '@/utils/utils';
	import { useRouter } from "vue-router";
	const router = useRouter();
	//直接跳转
	const handleChange = () => {
		if(isMobile()){
			router.push("/mHome");
		} else {
			router.push("/");
		}
	};
	
	onMounted(() => {
		// handleChange();
	})
</script>

<style>
	/* 字体文件的路径 */
	@font-face {
		font-family: "DinBold";
		src: url('./assets/font/DINPro-Bold.otf');
	}

	@font-face {
		font-family: "DinMedium";
		src: url('./assets/font/DIN-Medium.otf');
	}

	#app {
		font-family: PingFang SC Regular, 'Avenir', Helvetica, Arial, sans-serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		width: 100%;
		min-height: 100vh;
		color: #333;
		font-size: 20px;
		background-color: #fff;
		/* user-select: none; */
	}
	/* body::-webkit-scrollbar {
	  width: 0px;
	  height: 0px;
	} */
	
	.swiper-pagination-bullet {
		opacity: 1 !important;
		background: rgba(255,255,255,0.5) !important;
	}
	.swiper-pagination-bullet-active {
		width: 40px !important;
		border-radius: 4px !important;
		background: #fff !important;
	}
</style>