//路由文件
import {createRouter,createWebHistory} from 'vue-router' //将createRouter、createWebHistory引入vue
const routes = [{
		path: '/', //配置默认路由
		name: 'home', //路由名
		component: () => import("../views/home.vue") //引入该路由使用的组件
	},
	{
		path: '/plan_food',
		name: 'plan_food',
		component: () => import("../views/plan_food.vue")
	},
	{
		path: '/plan_industry',
		name: 'plan_industry',
		component: () => import("../views/plan_industry.vue")
	},
	{
		path: '/case',
		name: 'case',
		component: () => import("../views/case.vue")
	},
	{
		path: '/contact',
		name: 'contact',
		component: () => import("../views/contact.vue")
	},
	{
		path: '/news',
		name: 'news',
		component: () => import("../views/news.vue")
	},
	{
		path: '/news_details/:id',
		name: 'details',
		component: () => import("../views/news_details.vue")
	},
	{
		path: '/aboutUs',
		name: 'aboutUs',
		component: () => import("../views/aboutUs.vue")
	},
	{
		path: '/factory',
		name: 'factory',
		component: () => import("../views/factory.vue")
	},
	{
		path: '/mHome',
		name: 'mHome',
		component: () => import("../views/mobile/home.vue")
	},
	{
		path: '/mPlan_food',
		name: 'mPlan_food',
		component: () => import("../views/mobile/plan_food.vue")
	},
	{
		path: '/mPlan_industry',
		name: 'mPlan_industry',
		component: () => import("../views/mobile/plan_industry.vue")
	},
	{
		path: '/mFactory',
		name: 'mFactory',
		component: () => import("../views/mobile/factory.vue")
	},
	{
		path: '/mCase',
		name: 'mCase',
		component: () => import("../views/mobile/case.vue")
	},
	{
		path: '/mAboutUs',
		name: 'mAboutUs',
		component: () => import("../views/mobile/aboutUs.vue")
	},
	{
		path: '/mNews',
		name: 'mNews',
		component: () => import("../views/mobile/news.vue")
	},
	{
		path: '/mContact',
		name: 'mContact',
		component: () => import("../views/mobile/contact.vue")
	},
	{
		path: '/mNews_details/:id',
		name: 'mDetails',
		component: () => import("../views/mobile/news_details.vue")
	},

]
const router = createRouter({ //设置为history模式
	history: createWebHistory(),
	routes
})


export default router